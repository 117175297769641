.btn {
    display: inline-block;
    position: relative;
    clear: both;
    transition: all var(--time) var(--timing-function);
    background: var(--cl-cyan);
    border-radius: 4px;
    padding: var(--gs) calc(var(--gs) * 3.25);
    text-align: center;

    &:hover {
        background: var(--cl-lg-blue);
        border-color: transparent;
        fill: var(--cl-global);

        &::before {
            border-color: transparent;
        }
    }

    .icon {
        margin: -3px calc(var(--gs) * 1.5) 0 calc(var(--gs) * -0.5);
    }

    &--big {
        padding: calc(var(--gs) * 1.5) calc(var(--gs) * 3);
    }

    &--transparent {
        background: none;

        &::before {
            display: none;
        }

        &:hover {
            background: none;
        }
    }

    &--white {
        background: var(--cl-white);
        color: var(--cl-cyan);
        fill: var(--cl-cyan);

        &::before {
            transition: all var(--time) var(--timing-function);
            border: 2px solid var(--cl-cyan);
            border-radius: 4px;
            content: '';
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
        }
    }

    &--video {
        color: var(--cl-global);
        text-transform: none;
        &::before {
            border-color: var(--cl-divider-grey);
        }
    }

    &--pink {
        background: var(--cl-magenta);

        &:hover {
            background: var(--cl-pink);
        }
    }

    &--cart {
        .icon {
            width: 23px;
            height: 25px;
        }
    }

    &--wishlist {
        background: transparent;
        color: var(--cl-blue);
        fill: var(--cl-blue);
        text-align: left;
        font-size: 14px;
        padding: 8px 0;
        text-transform: none;

        &::before {
            transition: all var(--time) var(--timing-function);
            border: 2px solid transparent;
            border-radius: 4px;
            content: '';
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
        }

        &:hover {
            background: transparent;
            color: var(--cl-cyan);
            fill: var(--cl-cyan);

            &::before {
                border-color: transparent;
            }
        }

        .icon {
            margin: -3px var(--gs) 0 0;

            @media (--desktop) {
                margin-right: 0;
            }
        }
    }

    &--arrow {
        padding: 0;

        .icon {
            width: 10px;
            height: 16px;
            margin: calc(var(--gs) * 2) calc(var(--gs) * 2.5);
        }

        &-left {
            .icon {
                transform: rotate(180deg);
            }
        }

        &-top {
            .icon {
                transform: rotate(270deg);
            }
        }

        &-bottom {
            .icon {
                transform: rotate(90deg);
            }
        }
    }

    &--search {
        background: var(--cl-magenta);
        fill: var(--cl-white);
        padding: 0;

        .icon {
            width: 19px;
            height: 18px;
            margin: calc(var(--gs) * 2) calc(var(--gs) * 2);
        }

        &:hover {
            background: var(--cl-pink);
            fill: var(--cl-white);
        }
    }
}

.btn.is-disabled,
.is-disabled .btn,
.btn[disabled] {
    background-color: var(--cl-divider-grey);
    color: var(--cl-grey);
    fill: var(--cl-grey);
    border-color: transparent;
    pointer-events: none;

    &::before {
        content: none;
    }
}

.is-disabled .btn--transparent,
.btn--transparent.is-disabled,
.btn--transparent[disabled] {
    background-color: transparent;
}
