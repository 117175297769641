.icon {
    position: relative;
    display: inline-block;
    font-size: 0;
    line-height: 0;
    text-align: center;
    vertical-align: middle;
    transition: color var(--time) ease, background-color var(--time) ease, border-color var(--time) ease,
        opacity var(--time) ease;

    &--rotate {
        transform: rotate(180deg);
    }

    &--rocket {
        width: 45px;
        height: 45px;
    }

    &--study {
        width: 55px;
        height: 55px;
    }

    &--logo-greensight {
        width: 72px;
        height: 56px;
    }

    &--edit {
        width: 33px;
        height: 33px;
    }

    &--play {
        width: 80px;
        height: 80px;
    }

    &--wishlist {
        width: 22px;
        height: 20px;
    }

    &--share {
        width: 23px;
        height: 23px;
    }
    &--blue-vk {
        width: 27px;
        height: 27px;
    }
    &--blue-wa {
        width: 27px;
        height: 27px;
    }
    &--blue-tg {
        width: 27px;
        height: 27px;
    }
    &--blue-share {
        width: 27px;
        height: 27px;
    }

    &--heart {
        width: 22px;
        height: 20px;
    }

    &--cross-big {
        width: 38px;
        height: 38px;
    }

    &--cross-small {
        width: 17px;
        height: 17px;
    }

    &--cross {
        width: 25px;
        height: 25px;
    }

    &--cart {
        width: 27px;
        height: 29px;
    }

    &--document-empty {
        width: 62px;
        height: 82px;
    }

    &--star {
        width: 34px;
        height: 33px;
    }

    &--star-stroke {
        width: 34px;
        height: 33px;
    }

    &--review {
        width: 16px;
        height: 13px;
    }

    &--arrow {
        width: 22px;
        height: 24px;
    }

    &--info {
        width: 15px;
        height: 15px;
        margin-left: calc(var(--gs));
        cursor: pointer;
    }

    &--gift {
        width: 15px;
        height: 15px;
    }

    &--book {
        width: 20px;
        height: 18px;
    }

    &--download {
        width: 13px;
        height: 13px;
    }

    &--user {
        width: 31px;
        height: 31px;
    }

    &--mail {
        width: 55px;
        height: 55px;
    }

    &--polygon {
        width: 16px;
        height: 14px;
    }

    &--line {
        width: 10px;
        height: 16px;
    }

    &--line-small {
        width: 6px;
        height: 9px;
    }

    &--refresh {
        width: 37px;
        height: 40px;
    }

    &--call {
        width: 26px;
        height: 26px;
    }

    &--address {
        width: 26px;
        height: 36px;
    }

    &--pdf {
        width: 33px;
        height: 33px;
    }

    &--preview {
        width: 28px;
        height: 28px;
    }

    &--search {
        width: 19px;
        height: 18px;
        @media (--tablet) {
            width: 100%;
            height: 24px;
        }
    }

    &--check {
        width: 12px;
        height: 9px;
    }

    &--arrow-down {
        width: 10px;
        height: 5px;
    }

    &--quote {
        width: 17px;
        height: 15px;
    }

    &--logo-footer {
        width: 124px;
        height: 36px;
    }

    &--fb,
    &--telegram-svg,
    &--wa,
    &--im,
    &--yb,
    &--sms,
    &--yandex,
    &--google2,
    &--google {
        width: 40px;
        height: 40px;
    }

    &--fbblack,
    &--wablack,
    &--imblack,
    &--ybblack,
    &--sms,
    &--yandex,
    &--google2,
    &--google {
        width: 40px;
        height: 40px;
    }



    &--ok,
    &--twitter,
    &--vk {
        width: 41px;
        height: 40px;
    }

    &--burger {
        width: 13px;
        height: 12px;
    }

    &--logo {
        width: 215px;
        height: 51px;

        @media (--tablet-lg) {
            width: 115px;
            height: 28px;
        }
    }

    &--filter {
        width: 21px;
        height: 21px;
    }

    &--cabinet {
        width: 13px;
        height: 12px;
    }

    &--row-type {
        width: 24px;
        height: 21px;
    }

    &--column-type {
        width: 24px;
        height: 19px;
    }

    &--youtube {
        width: 30px;
        height: 32px;
    }

    &--visa {
        width: 54px;
        height: 17px;
    }
    &--gpay {
        width: 62px;
        height: 24px;
    }
    &--applepay {
        width: 62px;
        height: 24px;
    }

    &--mir {
        width: 56px;
        height: 16px;
    }

    &--credit {
        width: 84px;
        height: 50px;
    }

    &--tinkoff {
        width: 110px;
        height: 50px;
    }
    &--tinkoff-square {
        width: 35px;
        height: 35px;
        margin-right: 5px;
    }

    &--mastercard {
        width: 38px;
        height: 24px;
    }

    &--cart-empty {
        width: 96px;
        height: 103px;
    }

    &--eye-crossed {
        width: 20px;
        height: 12px;
    }

    &--eye {
        width: 18px;
        height: 12px;
    }

    &--eye-2 {
        width: 22px;
    }

    &--clock {
        width: 26px;
        height: 26px;
    }

    &--question {
        width: 32px;
        height: 32px;

        &-small {
            width: 14px;
            height: 14px;
        }
    }

    &--attension {
        width: 48px;
        height: 48px;

        &-small {
            width: 14px;
            height: 14px;
        }
    }

    &--wishlist-empty {
        width: 86px;
        height: 77px;
    }

    &--clip {
        width: 21px;
        height: 23px;
    }

    &--lock {
        width: 19px;
        height: 19px;
    }

    &--news-subscribe {
        width: 480px;
        height: 65px;
    }

    &--list {
        width: 24px;
        height: 21px;
    }

    &--dolyame {
        width: 130px;
        height: 18px;
        margin-left: 5px !important;
    }
}
