.modal-form-subscribe {
    .popup__body {
        padding-top: calc(var(--gs) * 3);
    }

    .popup__header {
        width: 100%;
    }

    &__header {
        display: flex;
        width: 100%;
        height: 65px;
        fill: var(--cl-cyan);

        @media (--tablet) {
            height: 48px;
        }
    }

    &__hl {
        margin-bottom: calc(var(--gs) * 4);
    }

    &__recaptcha {
        &-widget {
            margin-bottom: calc(var(--gs) / 2);
        }

        &-error {
            min-height: calc(var(--gs) * 3);
            margin-bottom: var(--gs);

            @media (--tablet) {
                min-height: calc(var(--gs) * 2.5);
            }

            &-message {
                position: relative;
            }
        }
    }

    &__submit {
        align-items: center;
        @media (--tablet) {
            flex-wrap: wrap;
        }

        &-link {
            @media (--tablet) {
                margin-bottom: calc(var(--gs) * 2);
            }
        }
    }
}
