/* Ошибки валидатора */

.error-message {
    color: var(--cl-magenta);
    position: absolute;
    bottom: 0;
    font-size: 14px;
    line-height: 20px;

    @media (--tablet) {
        font-size: 13px;
    }
}
