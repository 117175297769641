.v-input {
    position: relative;

    &__input {
        margin: 0 !important;
    }

    &__container {
        position: relative;
        display: flex;
        margin-bottom: calc(var(--gs) / 2);
    }

    &__error {
        position: relative;
        min-height: calc(var(--gs) * 2.5);
    }

    &__file-name {
         display: flex;
         align-items: center;
         margin-left: 10px;
    }

    &__file-field {
         display: none;
    }
    &__file-clip {
        cursor: pointer;
        color: #30BAEE;
        
        &-icon {
            fill: #30BAEE;
        }
    }
}
