.modal-form-claim {
    &__message {
        margin-bottom: calc(var(--gs) * 2);

        @media (--tablet) {
            margin-bottom: var(--gs);
        }
    }

    &__submit {
        justify-content: flex-end;
        margin-bottom: var(--gs);

        &-btn {
            margin-bottom: auto;

            @media (--tablet) {
                width: 100%;
            }
        }
    }

    &__policy {
        &-label {
            @media (--tablet-min) {
                margin-bottom: 0 !important;
            }
        }
    }

    &__error {
        min-height: calc(var(--gs) * 5);

        &-message {
            position: relative;
        }
    }
}
