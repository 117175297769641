body .course-banner {
    max-width: 750px;
    height: 100%;
    max-height: 48px;
    margin: 0;
}

.course-banner .swiper-slide img {
    width: 100%;
}
.course-banner .swiper-slide a {
    display: flex;
    align-items: center;
    cursor: pointer;
}

@media (max-width: 1024px) {
    body .course-banner {
        max-width: none;
        width: 100%;
    }
}
