.modal-forgot-password {
    &__text {
        margin: calc(var(--gs) * 3.5) 0;
    }

    &__link {
        .icon {
            transform: rotate(180deg);
        }
    }

    &__submit {
        align-items: center;
    }
    .post-loader {
        background: rgba(220,220,220,0.5);
        position: absolute;
        right: 0;
        bottom: 0;
        left: 0;
        top: 0;
    }
}
