.v-password {
    position: relative;

    &__input {
        padding-right: calc(var(--gs) * 6) !important;
        margin: 0 !important;
    }

    &__container {
        position: relative;
        margin-bottom: calc(var(--gs) / 2);
    }

    &__error {
        position: relative;
        min-height: calc(var(--gs) * 3);
    }

    &__btn-show {
        position: absolute;
        padding: 0;
        top: 50%;
        transform: translateY(-50%);
        right: calc(var(--gs) * 2);
        width: calc(var(--gs) * 3);

        .icon {
            margin: 0;
        }
    }
}
