.breadcrumbs {
    display: inline-flex;
    flex-wrap: wrap;

    &__item {
        &,
        a {
            font-weight: 500;
            font-size: 14px;
            line-height: 20px;
            color: var(--cl-grey);
            padding-left: 0;

            @media (--tablet) {
                font-size: 11px;
                line-height: 15px;
            }

            &:hover {
                color: var(--cl-cyan);
            }
        }

        padding-right: calc(var(--gs) * 1.5);
        margin-right: var(--gs);

        &::after {
            content: '>';
            position: absolute;
            right: 0;
        }

        &::before {
            content: none;
        }

        &:last-child {
            padding-right: 0;
            margin-right: 0;

            &::after {
                content: none;
            }
        }

        &.active {
            pointer-events: none;
        }
    }
}
