.footer-main {
    display: block;
    position: relative;
    margin-top: auto;
    padding-top: calc(var(--gs) * 5);
    padding-bottom: calc(var(--gs) * 7);
    background: var(--cl-global);
    min-width: 320px;

    @media (--desktop) {
        padding-bottom: calc(var(--gs) * 12);
    }

    @media (--tablet) {
        padding-bottom: calc(var(--gs) * 9);
    }

    &.html-modal-open {
        @media (--desktop-min) {
            padding-left: 17px;
            padding-right: 17px;
            width: calc(100% + 34px);
            margin: auto -17px 0 -17px !important;
        }
    }

    &__wrapper {
        display: flex;
        flex-wrap: wrap;
        max-width: 100%;
        font-size: 13px;
        color: var(--cl-white);

        &--col {
            flex-direction: column;
            flex: 1;

            &:first-of-type {
                padding-right: calc(var(--gs) * 2);
            }

            & + & {
                flex: 4;

                @media (--desktop) {
                    margin-top: calc(var(--gs) * 3);
                }
            }

            .icon {
                fill: var(--cl-global-invert);
            }
        }

        &--bottom {
            margin-top: calc(var(--gs) * 3);
            justify-content: space-between;
            align-items: flex-end;
        }

        &--main {
            @media (--desktop) {
                display: block;
            }
        }
    }

    &__agreement {
        margin-top: calc(var(--gs) * 4.5);
        margin-bottom: 0;
        max-width: 272px;
        font-size: 14px !important;
        line-height: 24px !important;

        @media (--tablet) {
            font-size: 13px !important;
        }

        &-item {
            padding-left: 0;
            font-weight: 500;
            font-size: 14px;
            line-height: 24px;

            &::before {
                content: none;
            }
        }
    }

    &__link {
        color: var(--cl-grey);
    }

    &__list {
        display: flex;
        flex-wrap: wrap;
        max-width: 100%;
        justify-content: space-between;

        @media (--tablet-lg) {
            justify-content: flex-start;
        }

        @media (--tablet) {
            display: block;
            column-count: 2;
        }
    }

    &__item {
        padding-left: 0;

        @media (--tablet-lg) {
            margin-right: calc(var(--gs) * 6);

            &:last-child {
                margin-right: 0;
            }
        }

        @media (--tablet) {
            margin-right: 0;
        }

        a {
            color: var(--cl-white);

            @media (--tablet-lg) {
                font-size: 15px;
            }

            @media (--tablet) {
                font-size: 13px;
            }

            &:hover {
                color: var(--cl-cyan);
            }
        }

        &:last-child {
            margin-right: 0;
        }

        &::before {
            content: none;
        }
    }

    &__news {
        display: block;
        position: relative;
        padding-right: 0;
        margin-bottom: calc(var(--gs) * 2);

        @media (--tablet-lg-min) {
            padding-right: calc(var(--gs) * 5);
            margin-bottom: 0;
        }

        @media (--tablet) {
            width: 100%;
            padding-right: 0;
        }

        &-input {
            width: 260px;

            @media (--tablet) {
                width: 100%;
            }

            .v-input__label {
                font-size: 16px;
                line-height: 24px;
                color: var(--cl-grey);

                @media (--tablet) {
                    font-size: 13px;
                    line-height: 20px;
                }
            }

            .v-input__container {
                margin-top: calc(var(--gs) / 2);
                margin-bottom: 0;
            }

            .v-input__input {
                border-top-right-radius: 0;
                border-bottom-right-radius: 0;
                border-right: none !important;

                @media (--tablet) {
                    width: calc(100% - calc(var(--gs) * 5));
                }
            }

            .v-input__error {
                display: none;
            }
        }

        &-btn {
            padding: 0;
            width: calc(var(--gs) * 5);
            height: calc(var(--gs) * 5);
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;

            .icon {
                margin: 0;
            }
        }
    }

    &__contacts {
        display: flex;
        align-items: flex-end;
        flex-wrap: wrap;

        @media (--tablet-lg) {
            width: 100%;
            align-items: center;
            flex-direction: column;
        }

        &--no-wrap {
            flex-wrap: nowrap;
        }
    }

    &__socials {
        display: flex;
        height: 100%;

        li {
            padding-left: 0;
            margin-right: 16px;

            &:last-child {
                margin-right: 0;
            }

            &::before {
                content: none;
            }

            .icon {
                @media (--tablet-lg) {
                    width: 32px;
                }
            }
        }
    }

    &__contact {
        display: flex;
        flex-direction: column;
        margin-left: calc(var(--gs) * 4.5);

        @media (--tablet-lg) {
            margin-top: calc(var(--gs) * 2);
            /*margin-left: calc(var(--gs) * 2);*/
        }

        &-btn {
            max-height: 40px;
            margin-right: calc(var(--gs) * 5);

            @media (--tablet-lg-diff) {
                padding: var(--gs) var(--gs);
            }

            @media (--tablet-lg) {
                width: 260px;
                margin-right: 0;
                margin-bottom: calc(var(--gs) * 2);
            }

            @media (--tablet) {
                width: 100%;
            }
        }

        a {
            color: var(--cl-white);
            line-height: 1.3rem;

            @media (--tablet) {
                font-size: 13px;
            }

            &:hover {
                color: var(--cl-cyan);
            }
        }
    }
}
