.modal-login {
    .popup__body {
        padding: 0;
    }

    &__form {
        display: flex;
        flex-direction: column;
        width: 100%;
        padding-top: calc(var(--gs) * 6);

        .form-login__container {
            padding: 0 calc(var(--gs) * 7);

            @media (--tablet) {
                padding: 0 calc(var(--gs) * 3);
            }
        }

        .form-login__footer {
            margin-top: auto;
        }
    }
}
