/* Шрифты */

@font-face {
    font-family: 'montserrat-critical';
    font-display: swap;
    src: url('../fonts/montserrat/montserrat-regular.woff2') format('woff2'),
        url('../fonts/montserrat/montserrat-regular.woff') format('woff');
}

@font-face {
    font-family: 'montserrat';
    font-display: swap;
    src: url('../fonts/montserrat/montserrat-regular.woff2') format('woff2'),
        url('../fonts/montserrat/montserrat-regular.woff') format('woff');
}

@font-face {
    font-family: 'montserrat';
    font-weight: 300;
    font-display: swap;
    src: url('../fonts/montserrat/montserrat-light.woff2') format('woff2'),
        url('../fonts/montserrat/montserrat-light.woff') format('woff');
}

@font-face {
    font-family: 'montserrat';
    font-weight: 500;
    font-display: swap;
    src: url('../fonts/montserrat/montserrat-medium.woff2') format('woff2'),
        url('../fonts/montserrat/montserrat-medium.woff') format('woff');
}

@font-face {
    font-family: 'montserrat';
    font-weight: 700;
    font-display: swap;
    src: url('../fonts/montserrat/montserrat-bold.woff2') format('woff2'),
        url('../fonts/montserrat/montserrat-bold.woff') format('woff');
}

body,
:--btn,
:--input,
.link,
.custom-select {
    font-family: 'montserrat', Arial, sans-serif;
}
