.form-login {
    background: var(--cl-white);

    &__header {
        margin-bottom: calc(var(--gs) * 4);

        @media (--tablet) {
            margin-bottom: calc(var(--gs) * 2.5);
        }
    }

    &__hl {
        display: inline-block;
        margin-right: calc(var(--gs) * 4);
        margin-bottom: 0;

        @media (--tablet) {
            margin-right: calc(var(--gs) * 2);
        }
    }

    &__password {
        &-label {
            display: flex;
            justify-content: space-between;
        }
    }

    &__submit {
        align-items: center;

        &-label {
            margin-bottom: 0 !important;

            @media (--tablet) {
                margin-bottom: calc(var(--gs) * 2) !important;
            }
        }

        &-btn {
            width: calc(var(--gs) * 21);
            @media (--tablet) {
                width: 100%;
            }
        }
    }

    &__error {
        min-height: calc(var(--gs) * 3);
        margin-bottom: calc(var(--gs) * 2);
        margin-top: calc(var(--gs) * 2);
    }

    &__footer {
        background: var(--cl-elem-grey);

        &-body {
            padding-top: calc(var(--gs) * 4);
            padding-bottom: calc(var(--gs) * 4);
        }

        &-hl {
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
            margin-bottom: calc(var(--gs) * 1.5);
        }

        &-list {
            display: flex;
            .v-list__item {
                margin-right: var(--gs);

                &:last-child {
                    margin-right: 0;
                }
            }
        }

        &-link {
            display: inline-block;
            .icon {
                margin: 0;
            }
        }
    }
}
