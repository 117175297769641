.menu-header {
    &__nav {
        &-list {
            .v-list__item {
                width: 100%;
            }
        }

        &-item {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 0 calc(var(--gs) * 2);
            height: calc(var(--gs) * 6);
            width: 100%;
            border-bottom: 1px solid var(--cl-divider-grey);
            user-select: none;

            &-number {
                margin-left: var(--gs);
                margin-right: auto;
                padding: 0 calc(var(--gs) / 2);
                min-width: calc(var(--gs) * 2);
                height: calc(var(--gs) * 2);
                line-height: calc(calc(var(--gs) * 2) + 1px);
                background-color: var(--cl-cyan);
                border-radius: 50%;
                font-size: 10px;
                color: var(--cl-white);
                text-align: center;
            }
        }
    }
}
