/* Таблицы */

.table {
    border-collapse: collapse;

    th,
    td {
        padding: var(--gs);
        border-bottom: 1px solid var(--cl-black);
        text-align: left;
    }

    th {
        border-width: 2px;
    }
}
