.modal-phone-callback {
    & .popup {
        width: 550px;
    }
    &__policy {
        /*margin-right: calc(var(--gs) * 2);*/

        &-label {
            @media (--tablet-min) {
                margin-bottom: 0 !important;
            }
        }
    }

    &__error {
        min-height: calc(var(--gs) * 5);

        &-message {
            position: relative;
        }
    }

    &__submit {
        justify-content: center;
        margin-bottom: var(--gs);

        &-btn {
            width: 100%;
            
            &:hover {
                background: var(--cl-lg-blue) !important;
            }
            
            @media (--tablet) {
                width: 100%;
            }
        }
    }
    
    .form__col-number {
        width: 60px;
        text-align: center;
    }
    .form__col-colon {
        color: var(--cl-global);
        font-size: 1rem;
        line-height: 1.5;
        padding-top: 6px;
        margin: 0 6px;
    }
    .v-select {
        width: 211px;
        
        @media (--tablet) {
            width: 100% !important;
            margin-bottom: var(--gs);
        }
    }
}
