.header-main {
    position: relative;
    height: calc(var(--gs) * 17);

    @media (--tablet-lg) {
        height: calc(var(--gs) * 6);
    }
    @media (--tablet) {
        height: calc(var(--gs) * 5);
    }

    & .link {
        & .icon {
            margin: 0;
        }
    }

    &__sticky-container {
        position: fixed;
        width: 100%;
        transition: box-shadow var(--time) var(--timing-function);
        z-index: 110;

        @media (--tablet-lg) {
            display: flex;
        }

        &--sticky {
            box-shadow: 0 1px 5px rgba(0, 0, 0, 0.2);
            top: 0;
            &.no-shadow {
                box-shadow: none;
            }
        }
    }

    &__right-block {
        display: flex;
        align-items: center;
        height: 100%;
    }

    &__container {
        display: flex;
        align-items: center;
        height: 100%;
        justify-content: space-between;

        @media (--tablet-lg) {
            min-width: 0;
            padding: 0;
        }
    }

    &__top.html-modal-open,
    &__panel.html-modal-open {
        @media (--desktop-min) {
            padding-right: 17px;
        }
    }

    &__top {
        order: 0;
        height: calc(var(--gs) * 6);
        line-height: 1;
        background: var(--cl-elem-grey);
        z-index: 110;
        @media (--tablet-lg) {
            order: 1;
            height: calc(var(--gs) * 6);
            padding: 0;
            background: var(--cl-white);
            border-bottom: 1px solid var(--cl-divider-grey);
        }
    }

    &__panel {
        order: 1;
        height: calc(var(--gs) * 11);
        padding: calc(var(--gs) * 2) 0;
        background: var(--cl-white);
        border-bottom: 1px solid var(--cl-divider-grey);
        z-index: 109;
        @media (--tablet-lg) {
            flex: 1;
            order: 0;
            height: calc(var(--gs) * 6);
            line-height: calc(var(--gs) * 6);
            padding: 0;
        }
    }

    &__item {
        display: flex;
        height: 100%;
        align-items: center;


        @media (--tablet-lg) {
            height: calc(var(--gs) * 6);
            display: flex;
            align-items: center;
            justify-content: center;
        }

        @media (--tablet) {
            width: calc(var(--gs) * 6);
            padding: 0;
            & + & {
                margin-left: 0;
            }
            &.bvi-open {
                width: auto;
            }
        }

        & + & {
            margin-left: calc(var(--gs) * 3);
            @media (--tablet-lg) {
                margin-left: 0;
                padding: 0 calc(var(--gs) * 2);
            }

            @media (--tablet) {
                margin-left: 0;
                padding: 0;
            }
        }

        & + &-phone {
            margin-left: auto;
        }

        & + &-logo {
            margin-left: 0;
            @media (--tablet) {
                flex: 1;
            }
        }

        &-number {
            margin-left: var(--gs);
            margin-right: auto;
            padding: 0 calc(var(--gs) / 2);
            min-width: calc(var(--gs) * 2);
            height: calc(var(--gs) * 2);
            line-height: calc(calc(var(--gs) * 2) + 1px);
            background-color: var(--cl-cyan);
            border-radius: 50%;
            font-size: 10px;
            color: var(--cl-white);
            text-align: center;
        }

        &-burger {
            margin: 0;
            width: calc(var(--gs) * 6);
            height: calc(var(--gs) * 6);
            @media (--tablet-lg-min) {
                display: none;
            }

            & .burger__line {
                width: calc(var(--gs) * 2);
                left: 42%;
                transform: translateX(-50%);
            }

            &::after,
            &::before {
                width: calc(var(--gs) * 3);
                left: 50%;
                transform: translateX(-50%);
            }

            &::after {
                margin-top: -8px;
            }

            &::before {
                margin-top: 8px;
            }

            &.is-open {
                &::before {
                    margin: 0;
                    transform: translateX(-50%) rotate(45deg);
                }
                &::after {
                    margin: 0;
                    transform: translateX(-50%) rotate(-45deg);
                }
            }
        }

        &-city {
            @media (--tablet-lg) {
                display: none;
            }

            &-label {
                display: inline-block;
                margin-right: var(--gs);
                text-transform: none;
            }

            &-value {
                display: inline-block;
                color: var(--cl-global);
                cursor: pointer;
                text-transform: none;
                font-size: 14px;
                font-weight: 500;
            }

            &-icon {
                fill: var(--cl-cyan);
            }
        }

        &-phone {
            font-weight: 500;
            font-size: 16px;
            min-width: 154px;
            margin-left: calc(var(--gs)*0);

            @media (--tablet) {
                min-width: 0;
                width: calc(var(--gs)*4);
            }

            &-text {
                @media (--tablet) {
                    display: none;
                }
            }

            .icon {
                &--phone {
                    @media (--tablet-min) {
                        display: none;
                    }
                }
            }
        }

        &-wa {
             font-weight: 500;
             font-size: 16px;
             margin-left: calc(var(--gs)*3);

            &-text {
                @media (--tablet) {
                    display: none;
                }
            }

            .icon {
                &--phone {
                    @media (--tablet-min) {
                        display: none;
                    }
                }
            }

            svg {
                height: 26px;
            }
        }

        &-menu {
            position: relative;
            line-height: 1;
            font-weight: 500;
            font-size: 14px;
            margin-right: calc(var(--gs)*1);

            @media (--tablet) {
                width: calc(var(--gs)*5);
            }

            .icon {
                &--cross,
                &--user {
                    width: 24px;
                    height: 24px;
                    @media (--tablet-min) {
                        display: none;
                    }
                }

                &--cabinet {
                    margin-right: var(--gs) !important;
                    fill: var(--cl-cyan);
                    @media (--tablet) {
                        display: none;
                    }
                }
            }

            &-login {
                color: var(--cl-blue);
                @media (--tablet) {
                    display: none;
                }

                &:hover {
                    color: var(--cl-cyan);
                }
            }

            &-text {
                @media (--tablet) {
                    display: none;
                }
            }

            &-label {
                cursor: pointer;
            }

            &-burger {
                margin-right: var(--gs);

                @media (--tablet) {
                    display: none;
                }

                &::before,
                &::after,
                & .burger__line {
                    background-color: var(--cl-cyan);
                }
            }

            & .header-main__popup-menu {
                position: absolute;
                top: 100%;
                z-index: 101;
                height: auto;
                width: 224px;
                left: calc(var(--gs) * -2);
                background: var(--cl-white);
                box-shadow: 0 3px 5px rgba(0, 0, 0, 0.2);

                @media (--tablet-lg-min) {
                    display: block;
                }

                .menu-header__nav-item {
                    padding: 0 0 0 calc(var(--gs) * 4);
                    border: none;
                    &:hover {
                        background: var(--cl-elem-grey);
                    }
                }
            }
        }

        &-wishlist {
            display: flex;

            &-inner {
                position: relative;
                display: flex;
                align-items: center;
            }

            .icon {
                &--wishlist {
                    width: 20px;
                    height: 24px;
                }
            }

            &-count {
                position: absolute;
                top: 0;
                left: 14px;
                padding: 0 calc(var(--gs) / 2);
                min-width: calc(var(--gs) * 2);
                height: calc(var(--gs) * 2);
                line-height: calc(calc(var(--gs) * 2) + 1px);
                background-color: var(--cl-cyan);
                border-radius: 50%;
                font-size: 10px;
                color: var(--cl-white);
                text-align: center;
            }
        }

        &-cart {
            display: flex;

            &-inner {
                position: relative;
                display: flex;
                align-items: center;
            }

            .icon {
                &--cart {
                    width: 23px;
                    height: 24px;
                }
            }

            &-count {
                position: absolute;
                top: 0;
                left: 14px;
                padding: 0 calc(var(--gs) / 2);
                min-width: calc(var(--gs) * 2);
                height: calc(var(--gs) * 2);
                line-height: calc(calc(var(--gs) * 2) + 1px);
                background-color: var(--cl-cyan);
                border-radius: 50%;
                font-size: 10px;
                color: var(--cl-white);
                text-align: center;
            }

            &-price {
                margin-left: calc(var(--gs) * 2);
                font-weight: bold;
                font-size: 14px;
                min-width: 140px;

                @media (--tablet) {
                    display: none;
                }
            }
        }

        &-logo {
            @media (--tablet) {
                width: auto;
            }
            &-link {
                display: inline-block;
            }
        }

        &-nav {
            margin-left: calc(var(--gs) * 6);
            width: 100%;
            @media (--tablet-lg) {
                display: none;
            }

            &-list {
                justify-content: space-between;
                align-items: center;
                flex-wrap: wrap;
                width: 100%;
                height: 100%;

                .v-list__item {
                    margin-right: calc(var(--gs) * 2);

                    .active {
                        color: var(--cl-cyan);
                        fill: var(--cl-cyan);
                    }

                    &:last-child {
                        margin-right: 0;
                    }
                }

                @media (--desktop) {
                    justify-content: flex-start;
                }
            }
        }
    }

    &__popup-menu {
        position: absolute;
        top: 100%;
        z-index: 101;
        width: 100%;
        left: 0;
        background: var(--cl-white);
        overflow-y: auto;
        overflow-x: hidden;

        @media (--tablet-lg) {
            height: calc(100vh - calc(var(--gs) * 6));
            padding-bottom: calc(var(--gs) * 8);
        }

        @media (--tablet-lg-min) {
            display: none;
        }

        &-name {
            background-color: var(--cl-elem-grey);
            border: none;
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
        }
    }

    &__socials {
        display: flex;
        @media (--mobile) {
            display: none;
        }

        li {
            padding-left: 0;
            margin-right: 10px;
            height: 100%;

            &::before {
                content: none;
            }
            &::last-child {
                margin-right: 0;
            }
            .icon {
                width: 20px;
                height: 20px;
            }
            .icon {
                @media (--tablet-lg) {
                    width: 20px;
                }

            }
            .icon.icon--wa-black {
                margin-top: 6px;
                @media (--tablet) {
                    width: 25px;
                    height: 25px;
                }
            }
        }
    }
    &__banner {
        display: flex;
        text-align: center;
        /*margin-left: auto;
        margin-right: 10px;*/
        /*background-color: #00A7E3;*/
         height: 100%;
        color: var(--cl-black);
        overflow: hidden;

        @media (--tablet-lg) {
            display: none;
        }

        &-top {
            display: none;
            justify-content: center;
            font-size: 10px;
            text-align: center;
            height: 100%;
            color: var(--cl-black);

            @media (--tablet-lg){
                display: flex;
            }
        }

        &-blue {
            font-weight: 600;
            color: var(--cl-blue);
            line-height: 1.333;
        }
        &-image {
            height: 100%;
            max-height: 48px;
        }
         /*&-image {
            max-height: 37px;
            margin-left: 10px;
            padding-top: 3px;
        }*/

    }

    &__submenu {
        list-style: none;
        margin: 0;
        padding: 0;
        background: white;
        border: 1px solid var(--cl-divider-grey);
        padding: calc(var(--gs));

        & li:before {
            display: none;
        }
        @media (--tablet-lg-min) {
            display: none;
            position: absolute;
            top: 2rem;
            left: -8px;

            & li {
                padding: 4px 8px;
            }
        }
    }
    &__topmenu:hover &__submenu {
        display: block;
    }
    @media (--tablet-lg-min) {
        &__topmenu {
            padding-bottom: 1rem;
            margin-top: 1rem;
        }
    }
    .btn:hover {
        fill: var(--cl-cyan);
        background: none;
    }
    &__search {
        @media (--tablet) {
            margin-right: 0;
            position: static;

            &-btn-search, &-btn-close, &-btn-show {
                width: calc(var(--gs) * 4) !important;
            }
        }

        margin: 0;
        margin-top: calc(var(--gs)*1);
        margin-right: calc(var(--gs)*1);
        height: calc(var(--gs) * 6);
        position: relative;

        &-input {
            position: absolute;
            z-index: 1;
            min-width: 350px;
            right: 0;
            @media (--tablet) {
                right: 12px;
            }
        }

        .v-input__input {
            padding-left: calc(var(--gs) * 7);
            padding-right: calc(var(--gs) * 6);
        }

        &-btn-search, &-btn-close, &-btn-show {
            padding: 0;
            background: none;
            height: calc(var(--gs) * 5);
            width: calc(var(--gs) * 6);

            .icon {
                margin: 0;
            }
        }
        &-btn-search, &-btn-close {
            position: absolute;
            z-index: 1;
        }
        &-btn-close {
            right: 0;
        }
    }

    .bvi-open {
        @media (--tablet-lg) {
            display: none;
        }
    }
}
