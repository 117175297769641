:root {
    --line-height: 3;
}

/* Вспомогательные классы */

.is-hidden {
    position: absolute !important;
    width: 1px !important;
    height: 1px !important;
    padding: 0 !important;
    border: 0 !important;
    overflow: hidden;
    clip: rect(1px, 1px, 1px, 1px);
}

.display-iblock {
    display: inline-block;
}

.display-block {
    display: block;
}

/* Float */

.pull-left {
    float: left !important;
}

.pull-right {
    float: right !important;
}

.pull-no {
    float: none !important;
}

/* скрыть блок, но оставить доступным для скринридеров */
.visually-hidden {
    position: absolute;
    overflow: hidden;
    clip: rect(0 0 0 0);
    height: 1px;
    width: 1px;
    margin: -1px;
    padding: 0;
    border: 0;
}

/* Clear */

.clearfix {
    &::after {
        content: '';
        display: block;
        clear: both;
    }
}

.clear-no {
    clear: none !important;
}

.clear {
    clear: both;
    height: 1px;
    line-height: 1px;
    overflow: hidden;
    margin-top: -1px;
}

.cl-red {
    color: var(--cl-red);
}

.cl-grey {
    color: var(--cl-grey);
}

.nowrap {
    white-space: nowrap;
}

/* Text-align */

.text {
    &-c {
        text-align: center !important;
    }

    &-l {
        text-align: left !important;
    }

    &-r {
        text-align: right !important;
    }
}

/* Размеры шрифта */

.fz {
    &-10 {
        font-size: 10px;
    }

    &-11 {
        font-size: 11px;
    }

    &-12 {
        font-size: 12px;
    }

    &-13 {
        font-size: 13px;
    }

    &-14 {
        font-size: 14px;
    }

    &-16 {
        font-size: 16px;
    }

    &-18 {
        font-size: 18px;
    }
}

/* Разные варианты text-decoration + подчеркивание dotted */

.text-dec {
    &-no,
    &-no:hover,
    &-und-h,
    &-no-h:hover {
        text-decoration: none;
    }

    &-und,
    &-und:hover,
    &-und-h:hover,
    &-no-h {
        text-decoration: underline;
    }

    &-dot {
        border-bottom: 1px dotted var(--cl-blue);
        text-decoration: none;
    }

    &-dot:hover,
    a:hover &-dot,
    button:hover &-dot {
        border-color: var(--cl-dk-blue);
        text-decoration: none;
    }
}

.very-fast {
    animation-duration: 0.25s;
}

.hook {
    position: absolute;
    height: 0;
    top: -148px;
    @media (--tablet-lg) {
        top: -68px;
    }
}

/* Класс, отрезающий текст, --line-height: высота строки */
.line-clamp {
    position: relative;
    overflow: hidden;
    max-height: calc(var(--line-height) * 2rem);

    /* &::after {
        content: '';
        text-align: right;
        position: absolute;
        bottom: 0;
        right: 0;
        width: 70%;
        height: calc(2em);
        background: linear-gradient(to right, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1) 50%);
    } */

    @supports (-webkit-line-clamp: var(--line-height)) {
        display: -webkit-box;
        -webkit-line-clamp: var(--line-height);

        & {
            /* autoprefixer: off */
            -webkit-box-orient: vertical;
        }

        &::after {
            display: none;
        }
    }
}

.no-line-clamp {
    max-height: inherit;
    -webkit-line-clamp: inherit;
}

.font-size-16 {
    font-size: 16px;
}

/* Копипаст части стилей из Bootstrap'а */
.m-0 {
  margin: 0 !important;
}

.mt-0,
.my-0 {
  margin-top: 0 !important;
}

.mr-0,
.mx-0 {
  margin-right: 0 !important;
}

.mb-0,
.my-0 {
  margin-bottom: 0 !important;
}

.ml-0,
.mx-0 {
  margin-left: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.mt-1,
.my-1 {
  margin-top: 0.25rem !important;
}

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important;
}

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important;
}

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.mt-2,
.my-2 {
  margin-top: 0.5rem !important;
}

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important;
}

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important;
}

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.mt-3,
.my-3 {
  margin-top: 1rem !important;
}

.mr-3,
.mx-3 {
  margin-right: 1rem !important;
}

.mb-3,
.my-3 {
  margin-bottom: 1rem !important;
}

.ml-3,
.mx-3 {
  margin-left: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.mt-4,
.my-4 {
  margin-top: 1.5rem !important;
}

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important;
}

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important;
}

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important;
}

.m-5 {
  margin: 3rem !important;
}

.mt-5,
.my-5 {
  margin-top: 3rem !important;
}

.mt-6,
.my-6 {
    margin-top: 6rem !important;
}

.mr-5,
.mx-5 {
  margin-right: 3rem !important;
}

.mb-5,
.my-5 {
  margin-bottom: 3rem !important;
}

.mb-6,
.my-6 {
    margin-bottom: 6rem !important;
}

.ml-5,
.mx-5 {
  margin-left: 3rem !important;
}

.p-0 {
  padding: 0 !important;
}

.pt-0,
.py-0 {
  padding-top: 0 !important;
}

.pr-0,
.px-0 {
  padding-right: 0 !important;
}

.pb-0,
.py-0 {
  padding-bottom: 0 !important;
}

.pl-0,
.px-0 {
  padding-left: 0 !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.pt-1,
.py-1 {
  padding-top: 0.25rem !important;
}

.pr-1,
.px-1 {
  padding-right: 0.25rem !important;
}

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important;
}

.pl-1,
.px-1 {
  padding-left: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.pt-2,
.py-2 {
  padding-top: 0.5rem !important;
}

.pr-2,
.px-2 {
  padding-right: 0.5rem !important;
}

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important;
}

.pl-2,
.px-2 {
  padding-left: 0.5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.pt-3,
.py-3 {
  padding-top: 1rem !important;
}

.pr-3,
.px-3 {
  padding-right: 1rem !important;
}

.pb-3,
.py-3 {
  padding-bottom: 1rem !important;
}

.pl-3,
.px-3 {
  padding-left: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.pt-4,
.py-4 {
  padding-top: 1.5rem !important;
}

.pr-4,
.px-4 {
  padding-right: 1.5rem !important;
}

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important;
}

.pl-4,
.px-4 {
  padding-left: 1.5rem !important;
}

.p-5 {
  padding: 3rem !important;
}

.pt-5,
.py-5 {
  padding-top: 3rem !important;
}

.pr-5,
.px-5 {
  padding-right: 3rem !important;
}

.pb-5,
.py-5 {
  padding-bottom: 3rem !important;
}

.pl-5,
.px-5 {
  padding-left: 3rem !important;
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

.text-justify {
    text-align: justify !important;
}