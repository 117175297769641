.burger {
    position: relative;
    width: 13px;
    height: 12px;

    &__line {
        display: block;
        position: absolute;
        top: 50%;
        left: 0;
        background-color: var(--cl-global);
        width: 100%;
        height: 2px;
        border-radius: 1px;
        margin-top: 0;
        transition: 0.2s ease-in-out 0.2s;
        pointer-events: none;
    }

    &.is-open &__line {
        background-color: transparent;
        transition-delay: 0s;
    }

    &::before,
    &::after {
        content: '';
        display: block;
        position: absolute;
        top: 50%;
        left: 0;
        background-color: var(--cl-global);
        width: 100%;
        height: 2px;
        border-radius: 1px;
        transition: margin 0.2s ease-in-out 0.2s, transform 0.2s ease-in-out 0s;
        pointer-events: none;
    }

    &::before {
        margin-top: -5px;
    }

    &::after {
        margin-top: 5px;
    }

    &.is-open {
        &::before,
        &::after {
            margin-top: 0;
            transition-delay: 0s, 0.25s;
            background-color: var(--cl-global);
        }

        &::before {
            transform: rotate(45deg);
        }

        &::after {
            transform: rotate(-45deg);
        }
    }
}
