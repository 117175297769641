.tags {
    &__label {
        margin-right: calc(var(--gs) * 2);
    }

    &__input {
        & + .tags__label {
            display: inline-block;
            padding: 0 calc(var(--gs) * 2);
            margin-bottom: 1px;
            height: 32px;
            line-height: 32px;
            border-radius: 32px;
            border: 1px solid transparent;
            background: var(--cl-elem-grey);
            color: var(--cl-global);
            transition: all var(--time) var(--timing-function);
            user-select: none;
            -webkit-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;

            @media (--tablet) {
                margin-bottom: var(--gs);
            }

            &::after,
            &::before {
                content: none;
            }

            &:last-of-type {
                margin-bottom: 0;
                margin-right: 0;
            }

            &:hover {
                background: var(--cl-global-invert);
                border-color: var(--cl-cyan);
                color: var(--cl-global);
            }
        }

        &:checked + .tags__label {
            color: var(--cl-global);
            background-color: var(--cl-cyan);
        }
    }
}
