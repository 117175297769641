.loader {
    position: absolute;
    z-index: 1;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    animation: lds-dual-ring 1.2s linear infinite;
    fill: var(--cl-grey);

    @keyframes lds-dual-ring {
        0% {
            transform: translate(-50%, -50%) rotate(0deg);
        }
        100% {
            transform: translate(-50%, -50%) rotate(360deg);
        }
    }
}
.post-loader {
    /*background: rgba(220,220,220,0.5);*/
    background: rgba(0,0,0,.5);
    position: fixed;
    right: 0;
    bottom: 0;
    left: 0;
    top: 0;
    z-index: 10000;
    
    .loader {
        fill: var(--cl-white);
    }
}