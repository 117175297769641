.v-list {
    display: flex;
    flex-wrap: wrap;

    &__item {
        /*display: inline-block;*/
        display: grid;
        padding: 0;
        &::before {
            display: none;
        }
    }
    
    &__w-100 {
        width: 100% !important;
    }
    
    &__hidden {
        visibility: hidden;
    }
}
