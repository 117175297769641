input[type='color'],
input[type='date'],
input[type='datetime'],
input[type='datetime-local'],
input[type='email'],
input[type='month'],
input[type='number'],
input[type='password'],
input[type='search'],
input[type='tel'],
input[type='text'],
input[type='time'],
input[type='url'],
input[type='week'],
input:not([type]),
textarea,
select,
.custom-select,
.v-select,
.v-combobox__input {
    border: 1px solid transparent;
    display: block;
    position: relative;
    width: 100%;
    vertical-align: top;
    zoom: 1;
    outline: none;
    transition: all var(--time) ease;
    height: var(--field-height);
    padding: calc(var(--gs) * 1.5) calc(var(--gs) * 2);
    font-size: 16px;
    background-color: var(--cl-white);
    border-color: var(--cl-divider-grey);
    color: var(--cl-global);
    border-radius: 4px;

    &:hover,
    &.is-hover {
        background-color: var(--cl-white);
        border-color: var(--cl-divider-grey);
        color: var(--cl-global);
    }

    &:focus,
    &.is-focus {
        background-color: var(--cl-white);
        border-color: var(--cl-cyan);
        color: var(--cl-global);
        outline: none;
    }

    &:active,
    &.is-active {
        background-color: var(--cl-white);
        border-color: var(--cl-divider-grey);
        color: var(--cl-global);
    }

    &.is-disabled,
    .is-disabled &,
    &:disabled {
        background-color: var(--cl-elem-grey);
        border-color: var(--cl-divider-grey);
        color: var(--cl-grey);
    }

    &.is-disabled,
    .is-disabled &,
    &[disabled] {
        cursor: default;
        pointer-events: none;
        background-color: var(--cl-elem-grey);
        color: var(--cl-global);
    }

    &::placeholder {
        color: var(--cl-grey);
    }

    &.is-invalid {
        border-color: var(--cl-magenta);
    }

    &:invalid,
    &:-moz-submit-invalid,
    &:-moz-ui-invalid {
        box-shadow: none;
    }
}

input[type='search']::-webkit-search-decoration,
input[type='search']::-webkit-search-cancel-button,
input[type='search']::-webkit-search-results-button,
input[type='search']::-webkit-search-results-decoration {
    display: none;
}

textarea {
    min-height: var(--field-height);
    height: auto;
    resize: vertical;
}

input[type='checkbox'],
input[type='radio'] {
    position: absolute;
    clip: rect(0, 0, 0, 0);

    & + label {
        position: relative;
        display: block;
        margin-bottom: calc(var(--gs) * 2);
        padding-left: 36px;
        font-size: 14px;
        line-height: 1.8;
        font-family: var(--arial);
        cursor: pointer;

        &::before,
        &::after {
            content: '';
            position: absolute;
            display: block;
            box-sizing: border-box;
            transition: opacity var(--time) ease;
        }

        &::before {
            content: '';
            top: 0;
            left: 0;
            width: 24px;
            height: 24px;
            border: 1px solid var(--cl-divider-grey);
            background: var(--cl-white);
        }

        &:last-of-type {
            margin-bottom: calc(var(--gs) * 3);
        }

        &.is-invalid {
            &:last-of-type {
                margin-bottom: var(--gs);
            }
        }
    }

    &:disabled {
        & + label {
            color: var(--cl-grey);
            cursor: default;

            &::before {
                border-color: var(--cl-divider-grey);
                background: var(--cl-bg-grey);
            }
        }
    }

    &:checked {
        & + label::after {
            background: var(--cl-cyan);
        }

        &:disabled + label::after {
            background: var(--cl-bg-grey);
        }
    }
}

input[type='checkbox'] + label::before,
input[type='checkbox'] + label::after {
    border-radius: 4px;
}

input[type='radio'] + label::before,
input[type='radio'] + label::after {
    border-radius: 50%;
}

input[type='radio'] + label::after {
    top: 9px;
    left: 9px;
    width: 6px;
    height: 6px;
}

input[type='checkbox'] + label::after {
    top: 1px;
    left: 1px;
    width: 22px;
    height: 22px;
}

input[type='checkbox']:checked + label::after {
    background: svg-load('../images/svg/check.svg', 'fill: #30baee') no-repeat center;
    background-size: 12px 9px;
}

.form {
    width: 100%;
    &__hl {
        font-size: 24px;
        line-height: 32px;

        @media (--tablet) {
            font-size: 20px;
            line-height: 28px;
        }
    }

    &__col {
        position: relative;
        width: 100%;
        margin-bottom: var(--gs);
    }

    &__cols {
        display: flex;
        justify-content: space-between;

        @media (--tablet) {
            flex-wrap: wrap;
        }

        .form__col {
            width: calc(50% - var(--gs));

            @media (--tablet) {
                width: 100%;
            }
        }

        &--third {
            .form__col {
                width: calc(calc(100% / 3) - calc(var(--gs) * 1.5));

                @media (--tablet) {
                    width: 100%;
                }
            }
        }
    }

    &__submit {
        display: flex;
        align-items: flex-start;

        @media (--tablet) {
            flex-direction: column;
        }

        &-btn {
            @media (--tablet) {
                width: 100%;
            }
        }
    }
}

@media (--tablet-lg-min) {
    .b24-window-popup-p-center .b24-form-header {
        position: relative;
        padding-left: 36% !important;
        padding-right: 55px !important;
    }
    .b24-window-popup-p-center .b24-form-header:before {
        display: block;
        content: '';
        background: url('../images/inhtml/juliana.png') no-repeat;
        width: 138px;
        height: 141px;
        background-size: 105%;
        background-position: -4px 0;
        position: absolute;
        left: 45px;
        bottom: 10px;
        border-radius: 50%;
        border: 1px solid var(--b24-field-border-color);
    }
    .b24-window-popup-p-center .b24-form-header-description {
        margin-bottom: 0 !important;
    }
}
