.link {
    color: var(--cl-blue);
    fill: var(--cl-blue);
    text-decoration: none;
    font-weight: normal;
    transition: all var(--time) var(--timing-function);
    font-size: 16px;
    line-height: 24px;
    cursor: pointer;
    text-transform: initial;

    .icon {
        margin: -3px calc(var(--gs) * 1.5) 0 calc(var(--gs) * -0.5);
    }

    &:hover {
        color: var(--cl-cyan);
        fill: var(--cl-cyan);
        text-decoration: none;
    }

    &--grey {
        color: var(--cl-grey);
        fill: var(--cl-grey);
    }

    &--sm {
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
    }

    &--black {
        color: var(--cl-global);
        fill: var(--cl-global);
    }

    &--wishlist {
        .icon {
            margin: -3px var(--gs) 0 0;

            @media (--desktop) {
                margin-right: 0;
            }
        }

        @media (--desktop) {
            font-size: 0;
            line-height: 0;
            margin-right: var(--gs);
        }

        &-convers {
            .icon {
                margin: 0;

                @media (--tablet) {
                    margin: -3px var(--gs) 0 0;
                }
            }

            @media (--tablet-min) {
                font-size: 0;
                line-height: 0;
            }
        }
    }
}

.link.is-disabled,
.is-disabled .link,
.link[disabled] {
    color: var(--cl-grey);
    fill: var(--cl-grey);
    border-color: transparent;
    pointer-events: none;

    &::before {
        content: none;
    }
}
